import React from 'react';
import useEnContactData from '../../shared/Hooks/ContactData/useEnContactData';
import Image from 'gatsby-image';
import {useEnBackgroundImage} from '../../shared/Hooks/Background/useEnBackgroundImage';

export const useData = () => {
  const cards = useEnContactData();
  const backgroundImgs = useEnBackgroundImage();

  return {
    seo: {
      title: 'Contact',
    },
    parallaxText: (
      <Image fixed={backgroundImgs.contact.childImageSharp.fixed}/>
    ),
    title: 'Contact',
    topLayout: {
      title: <>We invite you to work with us</>,
      text: <>
        Do you find our products & services of interest? <br/>
        Do you need an unconventional POSM solution? <br/>
        Write or call us - our specialists will support your vision with their knowledge and experience. <br/>
      </>,
    },
    cardContainer: {
      title: <>Do you want to talk to us?<br/></>,
      callText: 'Give us a call',
      cards,
    },
  };
};
