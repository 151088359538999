import {useContactIcons} from './useContactIcons'

const useContactData = ()=>{
    const icons = useContactIcons();

   return [
        {
            title: 'Would you like to get a price quote on your project?',
            icon: icons.laptop,
            email:'marketing@artprogres.com.pl'
        },
        {
            title: 'Are you looking for a job?',
            icon: icons.bag,
            email:'praca@artprogres.com.pl'
        },
        {
            title: 'We invite you to cooperate with us.',
            icon: icons.pen,
            email:'produkcja@artprogres.com.pl'
        },
        {
            title: 'Questions to the Accounting Department',
            icon: icons.comment,
            email:'ksiegowosc@artprogres.com.pl'
        }
    ];
}
export default useContactData;
